import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {path: "/", name: "home", component: () => import('../views/Home.vue')},
  {
    path: "/reservation/:id?", name: "reservation",
      component: () => import('../views/reservation/Reservation.vue'),
  },
  {
    path: "/crud/:model/:mode?/:id?",
    name: "crud",
    sensitive: true,
    component: () => import('../views/crud/Crud.vue'),
  },
  {
    path: "/developer/crud/diagnose", name: "crud-diagnose",
      component: () => import('../views/developer/Crud-Diagnose.vue'),
  },
  {
    path: "/developer/crud/edit", name: "crud-edit",
      component: () => import('../views/developer/Crud-Edit.vue'),
  },
  {
    path: "/schedule", name: "schedule",
      component: () => import("../views/schedule/Schedule.vue")
  },
  {
    path: "/period", name: "period",
      component: () => import("../views/Period.vue")
  },
  {
    path: "/member/past-orders/:memberId", name: "past-orders",
      component: () => import("../views/PastOrders.vue")
  },
  {
    path: "/announce/daily", name: "daily",
      component: () => import("../views/reports/DailyAnnounce.vue")
  },
  {
    path: "/reports/grow", name: "grow",
      component: () => import("../views/reports/Çoğalıyoruz.vue")
  },
  {
    path: "/reports/invoice", name: "invoice",
      component: () => import("../views/reports/Invoice.vue")
  },
  {
    path: "/reports/updaid", name: "unpaid",
      component: () => import("../views/reports/Unpaid.vue")
  },
  {
    path: "/reports/ar-choose", name: "ar-choose",
      component: () => import("../views/reports/ARChoose.vue")
  },
  {
    path: "/reports/from-web", name: "from-web",
      component: () => import("../views/reports/FromWeb.vue")
  },
  {
    path: "/reports/support-history", name: "support-history",
      component: () => import("../views/reports/SupportHistory.vue")
  },
  {
    path: "/reports/continuous", name: "continuous",
      component: () => import("../views/reports/Continuous.vue")
  },
  {
    path: "/reports/yearly-aggregate", name: "yearly-aggregate",
      component: () => import("../views/reports/YearlyAggregate.vue")
  },
  {
    path: "/reports/pos-failure", name: "pos-failure",
      component: () => import("../views/reports/PosFailure.vue")
  },
  {
    path: "/settings", name: "settings",
      component: () => import("../views/Settings.vue")
  },
  {
    path: "/pass", name: "change-pass", component: () => import("../views/ChangePass.vue")
  },
  {
    path: "/super", name: "super", component: () => import("../views/Super.vue")
  },
  //campaign:
  {
    path: "/campaign/call-form/:id?", name: "call-form",
      component: () => import("../views/call-form/CallForm.vue")
  },{
    path: "/campaign/call-report", name: "call-report",
      component: () => import("../views/call-form/CallReport.vue")
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router;
